// colors
@borderColor :#ccc;
@logoColor :#F55B23;  //全局的背景色
@bgColor: #fff;
@castboxMainBgColor:#f2f2f2;
@linkColorBlue: #428bca;
// size
@screen-xs-max : 767px;
@screen-sm-min : 768px;
@screen-sm-max : 991px;
@screen-md-min : 992px;
@screen-md-max : 1199px;
@screen-lg-min : 1200px;
@lg-min-width: 960px;
// 1
.font20bb{
  font-size: 20px;
  color: black;
  font-weight: 600;
}
// 2
.font14br{
  font-size: 14px;
  color: black;
  font-weight: 400;
}
// 3
.font12cr{
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
// 4
.font16cr{
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
// 5
.font30bb{
  font-size: 30px;
  color: black;
  font-weight: 600;
}
// 6
.font14cr{
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
// 7
.font16br{
  font-size: 16px;
  color: black;
  font-weight: 400;
}
// 8
.font16bb{
  font-size: 16px;
  color:black;
  font-weight: 600;
}
// 9
.font20cb{
  font-size: 20px;
  color: @logoColor;
  font-weight: 600;
}
// 10
.font20cr{
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
