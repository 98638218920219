// 播放列表淡显动画
@keyframes myopacityin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes myopacityin
/* Firefox */

{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes myopacityin
/* Safari and Chrome */

{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes myopacityin
/* Opera */

{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// animate play audio播放页旋转动画
@-webkit-keyframes play {
  0% {
    // // 水平翻转
    // -webkit-transform:rotateY(0deg);
    // // 垂直翻转
    // -webkit-transform:rotateX(0deg);
    // 顺时针旋转
    -webkit-transform: rotate(0deg); // // 逆时针旋转
    // -webkit-transform:rotate(0deg);
  }
  100% {
    //   // 水平翻转
    // -webkit-transform:rotateY(360deg);
    //   // 垂直翻转
    // -webkit-transform:rotateX(360deg);
    // 顺时针旋转
    -webkit-transform: rotate(360deg); // // 逆时针旋转
    // -webkit-transform:rotate(-360deg);
  }
}

@-moz-keyframes play {
  0% {
    // -moz-transform:rotateY(0deg);
    // -moz-transform:rotateX(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    // -moz-transform:rotateY(360deg);
    // -moz-transform:rotateX(360deg);
    -moz-transform: rotate(360deg); // -moz-transform:rotate(-360deg);
  }
}

@keyframes play {
  0% {
    // transform:rotateY(0deg);
    // transform:rotateX(0deg);
    transform: rotate(0deg);
  }
  100% {
    // transform:rotateY(360deg);
    // transform:rotateX(360deg);
    transform: rotate(360deg); // transform:rotate(-360deg);
  }
}

.loadingBox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  .loadingMain {
    @w: 1.6rem;
    position: absolute;
    left: 50%;
    top: 50%;
    width: @w;
    height: @w;
    margin: -@w/2 0 0 -@w/2;
    text-align: center;
    .loadImg {
      width: 52px;
      height: 67px;
    }
    .loadtext {
      color: #919191;
      font-size: 1em;
    }
  }
}

@media (min-width: 720px) {
  .loadingBox .loadingMain {
    @w: 160px;
    width: @w;
    height: @w;
    margin: -@w/2 0 0 -@w/2;
    .loadImg {
      width: 80px;
      height: 104px;
    }
    .loadtext {
      margin-top: -6px;
    }
  }
}

.opacityinAnimate {
  -webkit-animation: myopacityin .3s linear forwards;
  -moz-animation: myopacityin .3s linear forwards;
  animation: myopacityin .3s linear forwards;
}
