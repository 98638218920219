*,
*::before,
*::after {
  box-sizing: border-box;
}
main {
  display: block;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
body::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}
body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(158, 158, 158, 0.6);
}
body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(158, 158, 158, 0.9);
}
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  margin: 10px 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
  color: inherit;
  line-height: 1.2;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 10px;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding: 10px;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 10px;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 5px;
  margin-left: 0;
}
blockquote {
  margin: 0 0 10px;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 10px;
  overflow: auto;
}
figure {
  margin: 0 0 10px;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 5px;
}
button {
  border-radius: 0;
}
button:focus {
  outline: none;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: ' ';
}
.clearfix:after {
  clear: both;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
code,
pre {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
pre {
  display: block;
  padding: 10px;
  margin: 0 0 10px;
  font-size: 12px;
  line-height: 1.4;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 50px;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(0%);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(0vh);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(0%);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(0vh);
  content: '';
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.modal-header .close {
  padding: 15px;
  margin: -15px -15px -15px auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin-top: 15px;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.modal-footer > * {
  margin: 10px;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 50px auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(0%);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(0vh);
  }
  .modal-dialog-centered {
    min-height: calc(0%);
  }
  .modal-dialog-centered::before {
    height: calc(0vh);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/
.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.font-xs-t1 {
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 500;
  font-family: inherit;
}
.font-xs-t2 {
  font-size: 14px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: center;
  font-family: inherit;
}
.font_lg_t2 {
  font-size: 18px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: left;
  font-family: inherit;
}
html {
  font-size: 12px;
}
html #root {
  font-size: 14px;
}
@keyframes myopacityin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes myopacityin {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes myopacityin {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes myopacityin {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes play {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes play {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes play {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingBox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.loadingBox .loadingMain {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1.6rem;
  height: 1.6rem;
  margin: -0.8rem 0 0 -0.8rem;
  text-align: center;
}
.loadingBox .loadingMain .loadImg {
  width: 52px;
  height: 67px;
}
.loadingBox .loadingMain .loadtext {
  color: #919191;
  font-size: 1em;
}
@media (min-width: 720px) {
  .loadingBox .loadingMain {
    width: 160px;
    height: 160px;
    margin: -80px 0 0 -80px;
  }
  .loadingBox .loadingMain .loadImg {
    width: 80px;
    height: 104px;
  }
  .loadingBox .loadingMain .loadtext {
    margin-top: -6px;
  }
}
.opacityinAnimate {
  -webkit-animation: myopacityin .3s linear forwards;
  -moz-animation: myopacityin .3s linear forwards;
  animation: myopacityin .3s linear forwards;
}
/* public */
body {
  color: #565a5f;
  background-color: #fff;
  font-weight: 400;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-family: 'Hind Siliguri', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', sans-serif;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
body::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}
body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(158, 158, 158, 0.6);
}
body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(158, 158, 158, 0.9);
}
*:focus {
  outline: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  border: 0;
  color: inherit;
  outline-offset: 0;
}
.form-control:active,
.Select-control:active,
.form-control:focus,
.Select-control:focus,
.form-control:hover,
.Select-control:hover {
  outline: 0;
  border: #f55b23 solid 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(245, 91, 35, 0.6);
}
.left {
  float: left;
}
.right {
  float: right;
}
.childContent {
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 20px 10px;
  border-radius: 4px;
}
.childContent .guru-breadcrumb {
  border-bottom: 1px solid #dcdcdc;
}
.guru-btn-big {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 45px;
  border-radius: 4px;
}
.guru-btn-big.focus,
.guru-btn-big:focus,
.guru-btn-big:hover {
  color: #fff;
  /* transform: scale(1.05); */
  /* font-size: 12px; */
  text-decoration: none;
}
.childrenMain {
  margin-top: 42px;
}
.logo-btn {
  background-color: #f55b23;
  color: #fff;
  outline: none;
  border: 0;
  outline-offset: 0;
}
.logo-btn.focus,
.logo-btn:focus,
.logo-btn:hover {
  color: #fff;
  text-decoration: none;
}
.big-margin-top {
  margin-top: 50px;
}
.small-margin-top {
  margin-top: 20px;
}
.textRight {
  text-align: right;
}
.A_link {
  position: fixed;
  left: -500px;
  width: 100px;
}
.s-alert-wrapper .s-alert-effect-scale {
  border-radius: 6px;
  padding: 1em 1.5em;
  z-index: 9999;
}
.s-alert-wrapper .s-alert-effect-scale .s-alert-close {
  right: 0;
  top: 0;
}
.modal-dialog {
  margin: 50px auto 30px;
}
.categoritesSel.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.searchTypeSel.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.categoritesSel.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
.searchTypeSel.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #F55B23;
}
.categoritesSel.Select--single > .Select-control .Select-value,
.searchTypeSel.Select--single > .Select-control .Select-value,
.categoritesSel .Select-placeholder,
.searchTypeSel .Select-placeholder {
  color: #F55B23;
}
.categoritesSel .Select-control,
.searchTypeSel .Select-control,
.categoritesSel.is-focused:not(.is-open) .Select-control,
.searchTypeSel.is-focused:not(.is-open) .Select-control,
.categoritesSel.is-open .Select-control,
.searchTypeSel.is-open .Select-control {
  border: 3px solid #F55B23;
  border-color: #F55B23;
  box-shadow: none;
}
.categoritesSel .Select-control .Select-value,
.searchTypeSel .Select-control .Select-value,
.categoritesSel.is-focused:not(.is-open) .Select-control .Select-value,
.searchTypeSel.is-focused:not(.is-open) .Select-control .Select-value,
.categoritesSel.is-open .Select-control .Select-value,
.searchTypeSel.is-open .Select-control .Select-value {
  color: #F55B23;
}
.categoritesSel .Select-control .Select-arrow,
.searchTypeSel .Select-control .Select-arrow,
.categoritesSel.is-focused:not(.is-open) .Select-control .Select-arrow,
.searchTypeSel.is-focused:not(.is-open) .Select-control .Select-arrow,
.categoritesSel.is-open .Select-control .Select-arrow,
.searchTypeSel.is-open .Select-control .Select-arrow {
  border-color: #F55B23 transparent transparent;
}
.textLeft {
  display: inline-block;
  text-align: left;
}
.textLeft .textTwoClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.g-toast {
  top: 50px;
}
.g-toast-i {
  margin-bottom: 0.24rem;
  border-radius: 4px;
}
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
}
button,
input,
select,
textarea {
  outline: none;
}
input,
button {
  outline: none;
  border: 0;
}
input:active,
button:active,
input:focus,
button:focus,
input:hover,
button:hover {
  outline: none;
  border: 0;
}
button,
input,
select,
textarea {
  outline: none;
}
input,
button {
  outline: none;
  border: 0;
}
input:active,
button:active,
input:focus,
button:focus,
input:hover,
button:hover {
  outline: none;
  border: 0;
}
