/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/
.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.font-xs-t1 {
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 500;
  font-family: inherit;
}
.font-xs-t2 {
  font-size: 14px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: center;
  font-family: inherit;
}
.font_lg_t2 {
  font-size: 18px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: left;
  font-family: inherit;
}
html {
  font-size: 12px;
}
html #root {
  font-size: 14px;
}
#findAppBar {
  height: 1.2rem;
  background-color: rgba(53, 53, 53, 0.9);
  padding: 0.2rem 0;
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}
#findAppBar .findAppBar_con {
  height: 0.8rem;
  margin: 0 auto;
  width: 96%;
  max-width: 320px;
}
#findAppBar .findAppBar_con .text {
  padding: 0 .2rem 0 .85rem;
  width: 100%;
  font-size: 14px;
  color: #fff;
  height: 0.8rem;
  line-height: 0.8rem;
  background: url(/app/castbox/static/images/logo.png) no-repeat left center;
  background-size: .65rem .74rem;
}
#DownloadApp {
  height: 1.2rem;
  padding: 0.45rem 0;
  color: #fff;
  margin-top: -1.2rem;
}
#DownloadApp .logoText {
  height: 0.3rem;
}
#DownloadApp .downlink {
  font-size: 1.2em;
  margin-right: 0px;
}
#DownloadApp .downlink .DownloadApp_logo {
  height: 0.3rem;
  margin-left: 12px;
  font-size: 1.14em;
}
@media (max-width: 321px) {
  #findAppBar .findAppBar_con .text {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  #DownloadApp {
    margin-left: 0;
    margin-right: 0;
    background-color: rgba(53, 53, 53, 0.9);
  }
}
