/*
* base.less
*
* 此处存放基础设置下项：
* 1. 跟字号和rem缩放比例设置；
* 2. media查询区间变量
* 3. 一些字号的设置
*
*/

@import './setting.less';

// VVV font of indexPage and aboutPage

.font_40_48(@line:1) {
  font-size: 40px;
  line-height: 48px;
  height: @line*48px;
}
.font_36_43(@line:1) {
  font-size: 36px;
  line-height: 43px;
  height: @line*43px;
}
.font_30_36(@line:1) {
  font-size: 30px;
  line-height: 36px;
  height: @line*36px;
}
.font_24_29(@line:1) {
  font-size: 24px;
  line-height: 29px;
  height: @line*29px;
}
.font_28_33(@h:33px) {
  font-size: 28px;
  line-height: 33px;
  height: @h;
}
.border_radius(@r:15px) {
  border-radius: @r;
  -moz-border-radius: @r;
  -webkit-border-radius: @r;
}

//  xs font size
.font-xs-t1 {
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 500;
  font-family: inherit;
}
.font-xs-t2 {
  font-size: 14px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: center;
  font-family: inherit;
}
.font_lg_t2 {
  font-size: 18px;
  line-height: 160%;
  font-weight: inherit;
  color: #9b9b9b;
  text-align: left;
  font-family: inherit;
}

// 自适应 rem setting
//设计视图宽度
@view-base: 720;
@font-size-num: 100; //设置100是为了扩大倍数使更精确
@rembase: 1rem * @font-size-num;
@pxbase: 1px * @font-size-num;

html {
  font-size: 12px;
  #root {
    // 设置 root 根字号为14px
    font-size: 14px;
  }
}
