@import './base.less';
/* public */
// 全局设置
body {
  color: #565a5f;
  background-color: #fff;
  font-weight: 400;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-family: 'Hind Siliguri', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji',
    sans-serif;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(158, 158, 158, 0.6);
    &:hover {
      background-color: rgba(158, 158, 158, 0.9);
    }
  }
}
*:focus {
  outline: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  border: 0;
  color: inherit;
  outline-offset: 0;
}
.form-control,
.Select-control {
  &:active,
  &:focus,
  &:hover {
    outline: 0;
    border: #f55b23 solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(245, 91, 35, 0.6);
  }
}
.left {
  float: left;
}

.right {
  float: right;
}

.childContent {
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 20px 10px;
  border-radius: 4px;

  .guru-breadcrumb {
    border-bottom: 1px solid #dcdcdc;
  }
}

.guru-btn-big {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 45px;
  border-radius: 4px;

  &.focus,
  &:focus,
  &:hover {
    color: #fff;
    /* transform: scale(1.05); */
    // scale(0.99);
    /* font-size: 12px; */

    text-decoration: none;
  }
}

.childrenMain {
  margin-top: 42px;
}

.logo-btn {
  background-color: #f55b23;
  color: #fff;
  outline: none;
  border: 0;
  outline-offset: 0;
  &.focus,
  &:focus,
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.big-margin-top {
  margin-top: 50px;
}

.small-margin-top {
  margin-top: 20px;
}

.textRight {
  text-align: right;
}

.A_link {
  position: fixed;
  left: -500px;
  width: 100px;
}

.s-alert-wrapper {
  .s-alert-effect-scale {
    border-radius: 6px;
    padding: 1em 1.5em;
    z-index: 9999;

    .s-alert-close {
      right: 0;
      top: 0;
    }
  }
}

.modal-dialog {
  margin: 50px auto 30px;
}

.categoritesSel,
.searchTypeSel {
  // width: 125px;
  &.has-value.Select--single
    > .Select-control
    .Select-value
    .Select-value-label,
  &.has-value.is-pseudo-focused.Select--single
    > .Select-control
    .Select-value
    .Select-value-label {
    color: @logoColor;
  }

  &.Select--single > .Select-control .Select-value,
  .Select-placeholder {
    color: @logoColor;
  }

  &,
  &.is-focused:not(.is-open) >,
  &.is-open > {
    .Select-control {
      .Select-value {
        color: @logoColor;
      }
      border: 3px solid @logoColor;
      border-color: @logoColor;
      box-shadow: none;

      .Select-arrow {
        border-color: @logoColor transparent transparent;
      }
    }
  }
}

.textLeft {
  display: inline-block;
  text-align: left;

  .textTwoClamp {
    display: -webkit-box; // 设置display，将对象作为弹性伸缩盒子模型显示
    -webkit-line-clamp: 2; // 限制在一个块元素显示的文本的行数
    -webkit-box-orient: vertical; // 规定框的子元素应该被水平或垂直排列
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.g-toast {
  top: 50px;
  &-i {
    margin-bottom: 0.24rem;
    border-radius: 4px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}
button,
input,
select,
textarea {
  outline: none;
}
input,
button {
  outline: none;
  border: 0;
  &:active,
  &:focus,
  &:hover {
    outline: none;
    border: 0;
  }
}
button,
input,
select,
textarea {
  outline: none;
}
input,
button {
  outline: none;
  border: 0;
  &:active,
  &:focus,
  &:hover {
    outline: none;
    border: 0;
  }
}
